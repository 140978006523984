import Vue from 'vue'
import { Route } from 'vue-router'
import InfiniteLoading from 'vue-infinite-loading'
// @ts-ignore
import NoSsr from 'vue-no-ssr'
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import responseMixin from '@/mixins/responseMixin'
import seoSkillMixin from '@/mixins/seoSkillMixin'
import canonicalMixin from '@/mixins/canonicalMixin'
import { FREELANCERS_LIST, FREELANCERS_LIST_BY_SKILL } from '@/constants/routes'
import { RootState } from '@/store'
import Skill from '@/models-ts/Skill'
import { MAX_BUDGET, MIN_BUDGET, SORT_OPTIONS } from './FilterCard/FilterCard'
import FilterCard from './FilterCard/FilterCard.vue'
import FreelancersPalette from './FreelancersPalette/FreelancersPalette.vue'
import FreelancersLoader from './FreelancersLoader/FreelancersLoader.vue'
import skillMixins from './mixins/skillMixins'
import { SeoSkillEntityTypes, SeoSkillFieldTypes } from '@/constants/backend/SeoSkill'

const FREELANCERS_LIMIT = 30

const POPULAR_SKILLS = [
  'development',
  'blockchain-and-crypto',
  'design-and-creative',
  'nft',
  'writing',
  'sales-and-marketing',
]

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin, seoSkillMixin, skillMixins, canonicalMixin],
  components: {
    InfiniteLoading,
    NoSsr,
    FilterCard,
    FreelancersPalette,
    FreelancersLoader,
  },
  data () {
    return {
      FREELANCERS_LIST,
      preloading: false,
      infiniteId: 0,
      totalLoading: false,
      filtersCount: 0,
    }
  },
  computed: {
    ...mapState<RootState>({
      isLoggedIn: (state: RootState) => state.app.authorized,
      prefetched: (state: RootState) => state.browseFreelancers.prefetched,
      isLoaded: (state: RootState) => state.browseFreelancers.freelancers.isLoaded,
      isLoading: (state: RootState) => state.browseFreelancers.freelancers.isLoading,
      pagination: (state: RootState) => state.browseFreelancers.freelancers.pagination,
      userId: (state: RootState) => state.user?.id,
    }),
    ...mapGetters({
      freelancers: 'browseFreelancers/freelancers',
    }),
    totalJobsBySkill () {
      return this.skillDetails?.meta?.count?.jobs || 0
    },
    skillDetailsName () {
      const skillDetails = this.skillDetails as Skill
      if (!skillDetails) return ''
      const seoDescription = skillDetails.relations?.Seo.find(s =>
        s.entity_type === SeoSkillEntityTypes.FREELANCER &&
        s.field_type === SeoSkillFieldTypes.DESCRIPTION &&
        s.value)?.value
      return seoDescription || skillDetails?.name
    },
    popularSkills () {
      return POPULAR_SKILLS
        .map((url: string) => {
          return this.predefinedSkills.find((sk: any) => sk.url === url)
        })
        .filter(Boolean)
        .map((skill: any) => ({
          text: skill.name,
          link: { name: FREELANCERS_LIST_BY_SKILL, params: { skill: skill.url } }
        }))
    },
    totalFreelancers () {
      return this.skillDetails?.freelancers_start_of_month_counter
    },
    metaTitle () {
      if (!this.isLoaded) {
        return 'Loading'
      }
      return this.skillDetails
        ? `Hire ${this.skillDetailsName} freelancers and professionals`
        : 'Find & Hire Freelancers and Professionals'
    },
    metaDescription () {
      if (!this.skillDetails) {
        // eslint-disable-next-line max-len
        return '★ Hire the best freelancers and professionals online ➔ Lowest market commission. Find top freelancers and pros with LaborX ✔'
      }
      // eslint-disable-next-line max-len
      return `★ Hire the best ${this.skillDetailsName} freelancers and professionals online ➔ Lowest market commission. Find top ${this.skillDetailsName} freelancer and pros with LaborX ✔`
    },
  },
  watch: {
    async $route (newR, oldR) {
      if (newR.query !== oldR.query) {
        this.reloadFreelancers()
      }
    },
  },
  async prefetch () {
    if (process.server) {
      const skillUrl = this.$route.params.skill
      let predefinedSkills = []
      try {
        predefinedSkills = await this.getSkills()
      } catch (e) {
        console.error('Error fetching jobs:', e)
      }
      if (skillUrl) {
        if (!predefinedSkills.find((skill: Skill) => skill.url === skillUrl)) {
          this.setNotFound(true)
          this.setPrefetched(true)
          return
        }
        if (this.skillDetails.change_id) {
          const newSkill = predefinedSkills.find((skill: Skill) => skill.id === this.skillDetails.change_id)
          if (newSkill) {
            this.setRedirect(this.$route.fullPath.replace(this.skillDetails.url, newSkill.url))
            return
          }
        }
        if (this.skillDetails.is_removed) {
          this.setRedirect('/hire')
          return
        }
      }
      await this.loadFreelancers(this.getFilterFromQuery())
      this.setPrefetched(true)
    }
  },
  async created () {
    if (process.client) {
      try {
        await this.getSkills()
        if (!this.prefetched) {
          this.setPagination({ limit: FREELANCERS_LIMIT, offset: 0 })
          await this.loadFreelancers(this.getFilterFromQuery())
        } else {
          this.getFilterFromQuery()
        }
        this.infiniteId = +new Date()
        this.setPrefetched(false)
      } catch (e) {
        this.parseError(e)
      }
    }
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
      getSkills: 'skills/getSkills',
      loadFreelancers: 'browseFreelancers/loadFreelancers',
      loadMoreFreelancers: 'browseFreelancers/loadMoreFreelancers',
    }),
    ...mapMutations({
      setNotFound: 'app/setNotFound',
      setRedirect: 'app/setRedirect',
      setPrefetched: 'browseFreelancers/setPrefetched',
      setPagination: 'browseFreelancers/setPagination',
    }),
    onMoreSkillClick () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "jobs-modals" */ '@/modals/NavigationBySkills/NavigationBySkills.vue'),
          title: 'Categories',
        }
      })
    },
    async reloadFreelancers () {
      this.setPagination({ limit: FREELANCERS_LIMIT, offset: 0 })
      await this.loadFreelancers(this.getFilterFromQuery())
      this.$nextTick(() => this.infiniteId++)
    },
    async onLoadMore ($state: any) {
      try {
        if (this.freelancers.length < this.pagination.total) {
          this.totalLoading = true
          this.setPagination({ limit: FREELANCERS_LIMIT, offset: this.freelancers.length })
          await this.loadMoreFreelancers(this.getFilterFromQuery())
          return $state.loaded()
        }
        return $state.complete()
      } catch (e) {
        this.parseError(e)
      } finally {
        this.totalLoading = false
      }
    },
    getFilterFromQuery () {
      const {
        search,
        sort = null,
        budgetFrom,
        budgetTo,
        skill = [],
        review,
      } = this.$route.query
      const sortValue = !sort ? SORT_OPTIONS[4] : SORT_OPTIONS.find((opt: any) => opt.queryValue === sort)
      const bFrom = (Number.isNaN(+budgetFrom) || +budgetFrom === MIN_BUDGET) ? null : +budgetFrom
      const bTo = (Number.isNaN(+budgetTo) || +budgetTo === MAX_BUDGET) ? null : +budgetTo
      let skillsValue = [] as Array<Skill['id']>
      if (this.$route.params.skill) {
        const mainSkill = this.predefinedSkills.find((opt: any) => opt.url === this.$route.params.skill)
        if (mainSkill) {
          skillsValue.push(mainSkill.id)
        }
      }
      let skillsArray = Array.isArray(skill) ? skill : [skill]
      skillsValue = skillsValue.concat(skillsArray
        .map(id => this.predefinedSkills.find((opt: any) => +opt.id === +id)?.id)
        .filter(Boolean)
      )
      skillsValue = Array.from(new Set(skillsValue))
      this.filtersCount = 0 + (search ? 1 : 0) +
        (review ? 1 : 0) +
        (bFrom ? 1 : 0) +
        (bTo ? 1 : 0) +
        (skillsValue.length > 0 ? 1 : 0)
      return {
        search: search?.trim(),
        skills: skillsValue,
        sort: sortValue?.value,
        direction: sortValue?.direction || null,
        rateFrom: bFrom,
        rateTo: bTo,
        review: Number(review) || 0,
      }
    },
    onClickOpenFilters () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "jobs-modals" */ './FilterModal/FilterModal.vue'),
          title: 'Filters',
          props: {
          }
        }
      })
    },
  },
  metaInfo () {
    return {
      title: this.metaTitle,
      meta: this.metaDescription ? [
        { name: 'description', content: this.metaDescription },
      ] : [],
      link: [this.canonicalLink]
    }
  },
})
